import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_royals.videoUrl}
    staticAnnotation={videos.bally_royals.staticAnnotation}
    title="Watch Kansas City baseball live"
    subtitle="STREAM THE ROYALS ON BALLY SPORTS KANSAS CITY"
    customNetworks={['ballysportskansascity']}
    teamId={teamIds['Kansas City Royals']}
  />
)
