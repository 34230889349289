import React from 'react'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_ducks.videoUrl}
    staticAnnotation={videos.bally_ducks.staticAnnotation}
    title="Watch Anaheim hockey live"
    subtitle="STREAM THE DUCKS ON BALLY SPORTS SOCAL"
    customNetworks={['ballysportssocal']}
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    teamId={teamIds['Anaheim Ducks']}
  />
)
