import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_pacers.videoUrl}
    staticAnnotation={videos.bally_pacers.staticAnnotation}
    title="Watch Indiana basketball live"
    subtitle="STREAM THE PACERS ON BALLY SPORTS INDIANA"
    customNetworks={['ballysportsindiana']}
    teamId={teamIds['Indiana Pacers']}
  />
)
