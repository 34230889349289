import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import HeroVideo from './components/heroVideo'

import { LogoSyncContext } from 'src/components/common/AnnotationContext'

import { DefaultCtaText, defaultCTAURI, LatinoESDefaultCtaText } from 'src/modules/utils/constants'

import { useDispatch, useSelector } from 'react-redux'
import { selectParams, selectCTAParams, updateParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import CTAButton from 'src/components/common/CTAButtonNew'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import CTAButtonOfferCode from 'src/components/common/CTAButtonOfferCode'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { tablet } from 'bowser'

const HeroSectionWithBanner = ({
  lang = 'en',
  title,
  subtitle,
  tagline,
  ctaText,
  ctaHrefOverride,
  noteText,
  heroBgVideo,
  heroBgVideoMobile,
  containVideo = false,
  // add typecheck staticAnnotation for {logoUrl, alt} and in the future also allow customWidth prop
  staticAnnotation,
  posterImg,
  posterImgMobile,
  hasBanner,
  logo = 'https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_FC_RGB.png?w=300&ch=width&auto=format,compress',
  logoHeight,
  // AB Test for No Trial Discount
  onClick,
  hasOfferInput,
  // AB Test for Video vs Logo Soup
  noVideo,
  // Fallback for OfferCode and AddonCode
  offerCode,
  addonCode,
}) => {
  // get ctaParams from redux
  const params = useSelector(selectParams)
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  // offerCode and addonCode fallback
  let fallbackParams = {}
  if (offerCode) fallbackParams = { ...fallbackParams, offer: offerCode }
  if (addonCode) fallbackParams = { ...fallbackParams, 'addons[]': addonCode }
  if (
    (!parsedParams?.offerCode && fallbackParams.offer) ||
    (!parsedParams?.addonCode && fallbackParams['addons[]'])
  ) {
    ctaURL = createUrl(defaultCTAURI, { ...fallbackParams, ...parsedParams })
  }

  const [annotation, setAnnotation] = React.useState({
    logoUrl: null,
    logoWhite: null,
    alt: null,
    height: null,
  })

  const logoContextValue = React.useContext(LogoSyncContext)

  const [customBgVideo, setCustomBgVideo] = React.useState(false)

  React.useEffect(() => {
    if (params?.bgvideo) setCustomBgVideo(true)
  }, [params?.bgvideo])

  React.useEffect(() => {
    staticAnnotation
      ? setAnnotation(staticAnnotation)
      : logoContextValue && setAnnotation(logoContextValue)
  }, [logoContextValue, staticAnnotation])

  const [annotationComponent, setAnnotationComponent] = React.useState(null)
  const [isFadeIn, setIsFadeIn] = React.useState(false)
  React.useEffect(() => {
    if (annotation && annotation.logoUrl) {
      const component = (
        <AnnotationImage
          src={annotation.logoWhite || annotation.logoUrl}
          alt={annotation.alt}
          logoHeight={annotation.height ? annotation.height + 'px' : '40px'}
          height={annotation.height || 50}
          width="auto"
        />
      )
      setIsFadeIn(true)
      setAnnotationComponent(component)
      let fadeInTimeout = setTimeout(() => {
        setIsFadeIn(false)
      }, 500)
      return () => clearTimeout(fadeInTimeout)
    } else {
      setAnnotationComponent(null)
    }
  }, [annotation])

  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const ctaCopy = ctaText
    ? ctaText
    : lang === 'en'
    ? 'Start your free trial'
    : LatinoESDefaultCtaText

  // Click Tracking
  const handleOnClick = () => {
    fireUIInteractionEvent(
      {
        cta_name: ctaCopy,
        container_name: 'hero_section_cta_clicked',
      },
      stateForProtobuf
    )
  }
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'hero')
  return (
    <StyledHeroSection hasBanner={hasBanner} className={`hero-wrapper`} logoHeight={logoHeight}>
      <HeroVideo
        heroBgVideo={heroBgVideo}
        heroBgVideoMobile={heroBgVideoMobile}
        posterImg={posterImg}
        posterImgMobile={posterImgMobile}
        containVideo={containVideo}
        noVideo={noVideo}
      />
      <div className="content-container">
        <div className="hero-content">
          <div>
            <div className="logo-wrapper">
              <img src={logo} alt="Fubo Logo" width="100%" />
            </div>
            <div className="hero-wrapper">
              <div className="hero-text">
                <h1>{params.title || title}</h1>
                <h2>{params.subinfo || subtitle || tagline}</h2>
                {params.priceline ? (
                  <p>{params.priceline}</p>
                ) : (
                  subtitle && tagline && <p>{tagline}</p>
                )}
                <div className="cta-container" style={{ position: 'relative' }}>
                  {hasOfferInput ? (
                    <CTAButtonOfferCode
                      link={ctaHrefOverride || ctaURL}
                      onClick={onClick || handleOnClick}
                    >
                      {ctaText || 'Redeem Offer'}
                    </CTAButtonOfferCode>
                  ) : (
                    <CTAButton link={ctaHrefOverride || ctaURL} onClick={onClick || handleOnClick}>
                      {ctaCopy}
                    </CTAButton>
                  )}
                </div>
                {noteText && <div className="hero-note">{noteText}</div>}
              </div>
              <div className="hero-banner">
                <img src="https://assets.fubo.tv/marketing/lp/images/misc/compare-price.png?ch=width&auto=format,compress" />
              </div>
            </div>
          </div>

          {annotation && (
            <div className={'annotation-image bottomRight ' + (isFadeIn ? 'fade-in' : '')}>
              {annotationComponent}
            </div>
          )}
        </div>
      </div>
    </StyledHeroSection>
  )
}

export default HeroSectionWithBanner

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

// 3:2 Figma to Mobile (calculate 2/3 of figma sizing for mobile)

const StyledHeroSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-size: 100%;
  text-align: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;

  .content-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: ${desktopBreak}) {
      ${'' /* max-height: ${props => (props.hasBanner ? 'calc(100vh - 100px)' : '100vh')}; */}
    }

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      width: 100%;
      //   max-width: 1000px;

      box-sizing: border-box;
      background: rgba(3, 11, 24, 0.6);

      //   background: rgba(3, 11, 24, 0.4);
      @media screen and (min-width: ${desktopBreak}) {
        background: rgba(3, 11, 24, 0.6);
      }
    }

    .hero-content {
      padding: 54px 25px 100px;
      @media screen and (min-width: ${desktopBreak}) {
        padding: 100px 70px 140px 70px;
      }
      @media screen and (min-width: 1000px) {
        padding: 100px 100px 140px 100px;
      }
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      position: relative;
      z-index: 10;

      @media screen and (max-width: ${mobileBreak}) {
        .logo-wrapper {
          margin-bottom: 190px;
        }
      }

      .hero-wrapper {
        @media screen and (min-width: ${desktopBreak}) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .hero-text {
        @media screen and (min-width: ${desktopBreak}) {
          ${'' /* max-width: 470px; */}
          max-width: 435px;
          //   padding: 100px 25px 140px 100px;
        }
      }
      .hero-banner {
        flex-grow: 1;
        position: relative;
        img {
          width: 70%;
          max-width: 400px;
          min-width: 300px;
          height: auto;
          display: block;
          margin: auto;
          margin-top: 70px;
          @media screen and (min-width: ${desktopBreak}) {
            margin: 0;
            margin-right: 50px;
            margin-left: auto;
            margin-top: -20px;
          }
        }
      }

      .logo-wrapper {
        z-index: 20;
        width: auto;
        height: ${props => props.logoHeight || '56px'};
        position: relative;
        margin-bottom: 83px;
        @media screen and (min-width: ${desktopBreak}) {
          margin-bottom: 70px;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          width: auto;
        }
        @media screen and (max-width: ${mobileBreak}) {
          max-height: ${props => (props.logoHeight ? `calc(${props.logoHeight} * 7/8)` : '40px')};
        }
      }

      h1 {
        font-size: 40px;
        line-height: 1.16;
        font-weight: 800;
        color: #ffffff;

        width: 100%;
        margin-bottom: 16px;

        @media (min-width: ${desktopBreak}) {
          ${'' /* font-size: 74px; */}
          font-size: 60px;
          margin-bottom: 32px;
        }

        sup {
          font-size: 40%;
        }
      }
      h2 {
        font-size: 20px;
        line-height: 1.33;
        font-weight: 500;
        color: #ffffff;
        width: 100%;
        margin-bottom: 10px;

        @media (min-width: ${desktopBreak}) {
          font-size: 24px;
          margin-bottom: 20px;
          white-space: nowrap;
        }
      }
      p {
        font-size: 18px;
        line-height: 1.33;
        font-weight: 400;
        color: #ffffff;

        width: 100%;

        @media (min-width: ${desktopBreak}) {
          font-size: 22px;
        }

        sup {
          font-size: 40%;
        }
      }
    }
    .cta-container {
      margin-top: 30px;
      @media (min-width: ${desktopBreak}) {
        margin-top: 60px;
      }
    }
    .hero-note {
      margin-top: 14px;
      line-height: initial;
      font-size: 1.1rem;
      color: #ffffff;
      span {
        text-decoration: underline;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0.5;
      }
    }

    .annotation-image {
      transition: img ease;
      img {
        opacity: 0.5;
      }
      &.fade-in img {
        opacity: 0;
        animation: fade-in 0.48s forwards;
      }
    }
  }
`

const AnnotationImage = styled.img`
  max-width: 100%;
  width: auto;
  position: absolute;
  bottom: 25px;
  right: 3%;
  z-index: 20;

  @media (min-width: 605px) {
    width: auto;
    bottom: 25px;
    top: unset;
    right: 40px;
  }

  @media (max-width: 605px) {
    max-height: ${props => (props.logoHeight ? `calc(${props.logoHeight} * 5/8)` : '30px')};
    bottom: 20px;
    top: unset;
    height: auto;
  }
`
