import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="ATTPTHD"
    title="Watch AT&T SportsNet Pittsburgh Live with Fubo"
    networkName="AT&T SportsNet Pittsburgh"
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Pittsburgh Pirates']}
  />
)
