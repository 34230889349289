// Assuming you're using a standard setup with react, react-dom, styled-components, axios and @types/react installed
// If not, you can install them with yarn or npm. For example: npm install react react-dom styled-components axios @types/react

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import 'slick-carousel/slick/slick.css'
import { useSelector } from 'react-redux'
import { selectPostalCode } from 'src/modules/reducers/market'
import { fetchMatches } from 'src/modules/utils/fetchMatches'
import {
  selectAllChannels,
  selectCanadianChannels,
  selectSpainChannels,
  selectLatinoChannels,
} from 'src/modules/reducers/plans/selectors'
import ScheduleBanner from 'src/components/common/ScheduleBannerV2'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'

const MatchTicker = ({ lang, market, sportId, leagueName, leagueId, ctaText }) => {
  const usChannels = useSelector(selectAllChannels)
  const canadianChannels = useSelector(selectCanadianChannels)
  const spainChannels = useSelector(selectSpainChannels)
  const latinoChannels = useSelector(selectLatinoChannels)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  let availableChannels
  switch (market) {
    case 'us':
      availableChannels = usChannels
      break
    case 'canada':
      availableChannels = canadianChannels
      break
    case 'es':
      availableChannels = spainChannels
      break
    case 'latino':
      availableChannels = latinoChannels
      break
    default:
      availableChannels = usChannels
  }

  const [matches, setMatches] = useState(null)
  const [filteredMatches, setFilteredMatches] = useState(null)
  const [showMatches, setShowMatches] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const postal = useSelector(selectPostalCode)

  // Fetch data
  useEffect(() => {
    const fetchData = async (sportId, leagueId, postal) => {
      if (!postal) return
      if (!sportId && !leagueId) return
      setLoading(true)

      try {
        let matches
        if (Array.isArray(leagueId)) {
          // fetch all leagues in parallel
          const promises = leagueId.map(id => fetchMatches(sportId, id, postal))
          matches = await Promise.all(promises)
          // concat results
          matches = matches.flat()
          matches = matches.sort((a, b) => {
            const startTimeA = new Date(a.data.assets[0]?.accessRights?.startTime) || 0
            const startTimeB = new Date(b.data.assets[0]?.accessRights?.startTime) || 0
            return startTimeA - startTimeB
          })
        } else {
          matches = await fetchMatches(sportId, leagueId, postal)
        }
        console.log('matches', matches)
        setMatches(matches)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData(sportId, leagueId, postal)
  }, [sportId, leagueId, postal])

  // Filter data by plan, if any of assets[] has channel.callSign that match any callSign in availableChannels[]
  useEffect(() => {
    if (!matches || !availableChannels) return
    if (availableChannels.length) {
      let filteredMatches = matches.filter(match => {
        return match.data.program.metadata.teamsMetadata
      })

      filteredMatches = filteredMatches.reduce((acc, match) => {
        const { program, assets } = match.data

        const foundAsset = assets.find(asset => {
          const channelCallSign = asset.channel.callSign
          return availableChannels.some(channel => channel.callSign === channelCallSign)
        })

        if (foundAsset) {
          match.data.assets = [foundAsset] // Modify the match object to include only the "found" asset.
          acc.push(match) // Add the modified match object to the accumulator array.
        }
        return acc
      }, [])

      filteredMatches = filteredMatches.filter(match => {
        // If market is not latino, skip matches with a match.data.assets[0]?.network.name that contains "beIN Sports"
        if (market !== 'latino' && match.data.assets[0]?.network?.name?.includes('beIN Sports')) {
          // console.log('excluding' + match.data.program.heading)
          return false // Skip this match
        }

        return true
      })

      // Now, filter for duplicates, where two matchs have the same program.heading, and if there are duplicates, if one has program.displayLanguage === "es" and the other doesn't, remove the one with program.displayLanguage === "es". Likewise, if one of them has assets[0].qualifiers.is4K === true, remove that one.
      const map = new Map()

      filteredMatches.forEach(match => {
        const heading = match.data.program.heading

        // If we've seen this heading before
        if (map.has(heading)) {
          const existingMatch = map.get(heading)

          // Logic to keep the existing match if the new match has a displayLanguage of "es"
          if (match.data.program.displayLanguage === 'es') {
            return // Skip this match
          }

          // Logic to keep the existing match if the new match has a 4K asset
          if (match.data.assets[0]?.qualifiers?.is4K === true) {
            return // Skip this match
          }

          // Logic to replace the existing match if it has displayLanguage of "es" but the new one doesn't
          if (
            existingMatch.data.program.displayLanguage === 'es' &&
            match.data.program.displayLanguage !== 'es'
          ) {
            map.set(heading, match)
          }

          // Logic to replace the existing match if it has a 4K asset but the new one doesn't
          if (
            existingMatch.data.assets[0]?.qualifiers?.is4K === true &&
            match.data.assets[0]?.qualifiers?.is4K !== true
          ) {
            map.set(heading, match)
          }

          // If none of the above conditions are met, keep the first existing match
          return
        } else {
          map.set(heading, match)
        }
      })

      // Convert map values to array
      const dedupedMatches = Array.from(map.values())

      // setFilteredMatches(filteredMatches)
      setFilteredMatches(dedupedMatches)
    } else {
      // setFilteredMatches(matches)
    }
  }, [matches, availableChannels])

  const currentTimestamp = Date.now()

  const dateFormatOptions = { month: 'numeric', day: 'numeric' }
  const timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  }

  const items = filteredMatches?.map(match => {
    const { program, assets } = match.data

    const teamsMetadata = program?.metadata?.teamsMetadata

    const matchTimeStart = assets[0]?.accessRights?.startTime

    const matchTimeEnd = assets[0]?.accessRights?.endTime

    const date = new Date(matchTimeStart)

    const formattedDate = date.toLocaleDateString('en-US', dateFormatOptions)
    const formattedTime = date.toLocaleTimeString('en-US', timeFormatOptions)

    const meta = (
      <div className="carousel-meta-text">
        <span>{assets[0]?.channel?.displayName}</span>
        <span>•</span>
        <span className="carousel-meta-text-time">
          <span>{formattedDate}</span>
          <span>•</span>
          <span>{formattedTime}</span>
        </span>
      </div>
    )

    const home = teamsMetadata?.homeTeam?.name
    const away = teamsMetadata?.awayTeam?.name

    const customTitle = `${home} vs ${away}`

    return {
      thumbnail: program.horizontalImageWithTitle,
      title: customTitle,
      meta: meta,
      live: currentTimestamp > matchTimeStart && currentTimestamp < matchTimeEnd,
    }
  })

  let title = 'Watch with Fubo'
  if (lang === 'es') {
    title = 'Mira con Fubo'
  }

  useEffect(() => {
    if (filteredMatches && filteredMatches.length) {
      setShowMatches(true)
    }
  }, [filteredMatches])

  useEffect(() => {
    if (showMatches) {
      fireUIInteractionEvent(
        {
          cta_name: leagueName,
          cta_description: filteredMatches.length,
          container_name: 'show_matchticker',
        },
        stateForProtobuf
      )
    }
  }, [showMatches])

  return (
    <>
      {showMatches ? (
        <StyledMatchTicker>
          <ScheduleBanner lang={lang} items={items} title={title} ctaText={ctaText} />
        </StyledMatchTicker>
      ) : null}{' '}
    </>
  )
}

export default MatchTicker

const StyledMatchTicker = styled.div`
  position: relative;
  background: #17181f;
  border-bottom: 2px solid #30313a;
  color: #fafafa;

  .carousel-meta-text,
  .carousel-meta-text .carousel-meta-text-time {
    display: flex;
    gap: 1px 5px;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      gap: 1px 3px;
    }
  }
`
