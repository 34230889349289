import React, { useState } from 'react'
import { getUniqueIdCookie } from '@fubotv/cookies'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAnalyticsPageViewSent,
  selectAnalyticsReady,
  updateAnalyticsProperties,
  updateAnalyticsReady,
  updatePageViewSent,
  updateParameters,
} from 'src/modules/reducers/analytics'
import { call } from 'src/modules/reducers/api-client'
import { getLocation } from '@fubotv/api-client/lib/domains/location'
import { track } from 'src/modules/analytics/track'
import bowser from 'bowser'
import { nanoid } from 'nanoid'
import { updateMarket } from 'src/modules/reducers/market'
import { parseFlagsQueryParams, parseQueryParams } from 'src/modules/utils/parseQueryParams'
import { fetchPlans } from 'src/modules/reducers/plans/actions'
import { updateParams } from 'src/modules/reducers/params'
import initAnalytics from 'src/modules/analytics/vendor'
import { initStacFlags, tryFetchStacFlags } from 'src/modules/reducers/cohorts/actions'
import { fireCohortingEvent, fireProtoPageView } from 'src/modules/analytics/v3'
import { tryFetchProducts } from 'src/modules/reducers/products/actions'
import { selectStacFlagsReady } from 'src/modules/reducers/cohorts'
import { selectStateForProtobuf } from 'src/modules/reducers'

const Analytics = ({ lang, market, sportId, teamId, leagueId }) => {
  const dispatch = useDispatch()
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  // Get geolocation data
  const fetchGeolocation = async () => {
    try {
      const res = await call(getLocation)
      if (res) {
        const data = {
          countryCode: res.countryCode,
          countryCode2: res.countryCode2,
          countryName: res.countryName,
          dma: res.dma,
          postalCode: res.postalCode,
        }
        return data
      }
    } catch (error) {
      if (error.response) {
        console.log('location error', error.response)
      } else if (error.request) {
        console.log('location error', error.request)
      } else {
        console.log('location error', error.message)
      }
    }
  }

  // Initialize Analytics
  const initAnalyticsPackage = () => {
    initAnalytics()
    window.analytics &&
      window.analytics.ready &&
      window.analytics.ready(() => {
        dispatch(updateAnalyticsReady())
        const amplitudeInstance = window.amplitude && window.amplitude.getInstance()
        const amplitudeDeviceId =
          amplitudeInstance && amplitudeInstance.options && amplitudeInstance.options.deviceId
        const uniqueId = amplitudeDeviceId || getUniqueIdCookie()
        if (uniqueId) {
          window.analytics.user().anonymousId(uniqueId)
          getLocationAndCohorting(uniqueId)
        }
      })
  }

  //send cohorting events

  const sendCohortingEvents = async (marketState, deviceId) => {
    let queryParams = parseQueryParams(window.location.href)
    let flags = {}
    dispatch(initStacFlags({}))
    try {
      flags = await dispatch(
        tryFetchStacFlags(undefined, parseFlagsQueryParams(queryParams), deviceId)
      )
    } catch (error) {
      console.log('error fetching cohorting', error)
    } finally {
      fireCohortingEvent({ market: marketState, cohorts: { flags } })
    }
  }

  // Send page view
  const sendPageView = async () => {
    const updatedAnalyticsProperties = await fireProtoPageView(stateForProtobuf)
    dispatch(updatePageViewSent())
    dispatch(updateAnalyticsProperties(updatedAnalyticsProperties.properties))
  }

  const getLocationAndCohorting = async uniqueId => {
    let geolocation = null
    var language = window.navigator.userLanguage || window.navigator.language

    try {
      geolocation = await fetchGeolocation()
      dispatch(updateMarket({ ...geolocation, market, lang, language, sportId, leagueId, teamId }))
    } catch (error) {
      console.log('error fetching geolocation', error)
    } finally {
      sendCohortingEvents(
        { ...geolocation, market, lang, language, sportId, leagueId, teamId },
        uniqueId
      )
    }
  }

  const location = typeof window !== 'undefined' && window.location

  React.useEffect(() => {
    initAnalyticsPackage()
    //TODO: add query to override cohort
    dispatch(fetchPlans())
    dispatch(tryFetchProducts())
  }, [])

  // Get request query params to save into store
  React.useEffect(() => {
    let queryParams = parseQueryParams(location.href)
    const referrer = window?.document?.referrer
    if (Object.keys(queryParams).length > 0) {
      if (referrer.includes('.google.') || referrer.includes('.bing.')) {
        if (queryParams.irad) {
          delete queryParams.irad
          console.log('removing irad', queryParams.irad)
        }
        if (queryParams.irmp) {
          delete queryParams.irmp
          console.log('removing irmp', queryParams.irmp)
        }
      }
      dispatch(updateParameters(queryParams))
      dispatch(updateParams(queryParams))
    }
  }, [location])

  //TODO: once analytics is loaded and read, send page view
  const analyticsReady = useSelector(selectAnalyticsReady)
  const pageViewSent = useSelector(selectAnalyticsPageViewSent)
  const cohortingReady = useSelector(selectStacFlagsReady)
  React.useEffect(() => {
    if (
      cohortingReady &&
      !pageViewSent &&
      typeof window !== 'undefined' &&
      window.analytics &&
      analyticsReady
    ) {
      sendPageView()
    }
  }, [analyticsReady, cohortingReady])

  return null
}

export default Analytics
