import React from 'react'
import Layout from 'src/components/common/Layout'
import styled from 'styled-components'

import { defaultCTAURI } from 'src/modules/utils/constants'
import { createUrl } from 'src/modules/utils/createUrl'
import { selectCTAParams } from 'src/modules/reducers/params'
import { useSelector } from 'react-redux'
import SEO from 'src/components/common/SEO'

const AffiliateTemplate = ({ lang = 'en', market = 'all' }) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  const pageTitle = 'Affiliate Program | Fubo'
  const pageDescription =
    'Get paid to share the passion of sports and entertainment. Fubo is the leading streaming subscription service for live sports and entertainment. The affiliate program provides a unique way for your website or app to link to hundreds of live games, leagues, award shows, TV series and more while earning revenue from subscriptions.'

  return (
    <Layout lang={lang} market={market}>
      <SEO title={pageTitle} description={pageDescription} />
      <StyledAffiliate>
        <nav>
          <div className="nav-content">
            <div className="logo">
              <img
                src={
                  'https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_WT_RGB.png?w=300&ch=width&auto=format,compress'
                }
                alt="Fubo Logo"
              />
            </div>
            <div>
              <a
                href="https://app.impact.com/campaign-promo-signup/fuboTV.brand?execution=e6s1"
                className="button-cta"
              >
                Become a Partner
              </a>
            </div>
          </div>
        </nav>
        <div className="main-content">
          <div className="section">
            <h1>Fubo Partner Program</h1>
            <h4>Get paid to share the passion of sports and entertainment</h4>
            <div className="device-img">
              <img
                src="https://assets-imgx.fubo.tv/marketing/lp/images/devices/NEW_devices_2023.png?ch=width&auto=format,compress"
                alt="various streaming devices with Fubo app"
              />
            </div>
            <p>
              Fubo is a service that broadcasts live TV over the internet, no cable required. Watch
              your favorite teams, network shows, news, and movies on 350+ channels. Plus, on-demand
              entertainment, including full TV series and movies.
            </p>
          </div>
          <hr />
          <div className="section mb">
            <h2>Program Details</h2>
            <div>
              <h3>About Fubo</h3>
              <p>
                Fubo is the live TV streaming platform with more top Nielsen-ranked sports, news and
                entertainment channels for cord-cutters than any other live platform. Its base
                package, Fubo Pro, features more than 150 channels, personal Cloud DVR storage, and
                10 simultaneous streams - with no contract or set-top box required. Get Fubo on the
                web, on popular mobile phones and tablets, and connected and smart TVs.
              </p>
              <p>
                Continually innovating to give subscribers a premium viewing experience they can't
                find with cable TV, Fubo is regularly first-to-market with new product features,
                including being the first virtual MVPD to stream regularly in 4K Ultra HD. Other
                industry "firsts" for the company include entering Europe with the launch of Fubo
                España in 2018. Fubo Sports Network, the live, free-to-consumer TV network for
                passionate sports fans, launched in 2019 with event coverage, news,
                behind-the-scenes, and exclusive programming. Fubo has also partnered with Ryan
                Reynolds' Maximum Effort to create the Maximum Effort Channel, a 24/7 live channel
                of classic TV shows, original programming and unique advertising opportunities.
              </p>
            </div>
            <div>
              <h3>Compensation and Tracking</h3>
              <p>
                Fubo pays a commission for every qualified referral, tracking and payments are
                handled by a third party network (Impact Radius).
              </p>
            </div>
            <div>
              <h3>Compatibility</h3>
              <p>
                Fubo content is currently available in the United States, Spain, and Canada.
                Packaging and pricing vary by region. Users can access live content via Desktop,
                iPhone, iPad, Android Phone & Tablet, Roku, Amazon Fire TV, and Apple TV (Gen 4) as
                well as select Smart TV's. Fubo has DVR capability, and content is available in
                English, Spanish, Italian and Portuguese.
              </p>
              <p>
                *Regional Sports Networks and Local Networks are only available in their respective
                markets.
              </p>
            </div>
            <div>
              <h3>Become a Partner Today</h3>
              <p>
                If you wish to monetize your website or app and think Fubo is the right partner{' '}
                <a
                  href="https://app.impact.com/campaign-promo-signup/fuboTV.brand?execution=e6s1"
                  target="_blank"
                  rel="noreferrer"
                >
                  APPLY NOW
                </a>
                .
              </p>
            </div>
          </div>

          <div className="section">
            <h2>FAQ</h2>
            <div>
              <h3>How do I apply?</h3>
              <p>
                Applying to the Fubo Partners Program is easy, simply click{' '}
                <a
                  href="https://app.impact.com/campaign-promo-signup/fuboTV.brand?execution=e6s1"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                , and fill out the details. Once approved you'll be able to obtain marketing assets
                and links within the Impact Radius dashboard.
              </p>
            </div>

            <div>
              <h3>Can I promote Fubo from a non-US website?</h3>
              <p>
                Yes. You can promote Fubo from anywhere in the world. If you have the traffic coming
                into your site from the US, Canada, or Spain, you can monetize this traffic by
                adding Fubo tracking links.
              </p>
            </div>

            <div>
              <h3>Are leagues like the NFL, NBA, MLB and others available?</h3>
              <p>
                Absolutely! Not only does Fubo have the NFL, NBA, and MLB, but you can also watch
                NHL, NASCAR, golf, MMA, tennis, college sports, F1 racing and more. Plus coverage of
                specials including the Super Bowl, World Series, Stanley Cup Playoffs, Olympics, NBA
                Finals and the Triple Crown.
              </p>
            </div>

            <div>
              <h3>Can I record games & shows?</h3>
              <p>
                Yes, all U.S. Fubo plans include unlimited Cloud DVR space for recording all your
                favorite sports, shows, movies & news at no extra charge. Hit record on one device
                and watch that recorded content on another. Recorded content will be available in
                your library for nine months.
              </p>
            </div>
            <div>
              <h3>Can I stream shows on my TV?</h3>
              <p>
                Yes. The easiest way to watch Fubo on your TV is with a Roku, Apple TV, Amazon Fire
                TV, Smart TV, Chromecast or other connected TV device. Simply install the Fubo app
                on your device to stream on your TV.
              </p>
            </div>

            <div>
              <h3>Does Fubo have channels like ABC, NBC, FOX and CBS?</h3>
              <p>
                Yes, Fubo has ABC*, CBS*, FOX* and NBC* as well as ESPN, FX, SHOWTIME®️, Starz,
                CNBC, SYFY, USA Network, Bravo, MTV, E!, Disney Channel and others. *Regional
                restrictions apply.{' '}
                <a href="https://www.fubo.tv/welcome" target="_blank" rel="noreferrer">
                  See our full channel line-up.
                </a>
              </p>
            </div>

            <div>
              <h3>Do users have access to Regional Sports Networks with Fubo?</h3>
              <p>Yes, Fubo has many RSN's in the US including:</p>
              <p>
                Altitude Sports, AT&T SportsNet Pittsburgh, AT&T SportsNet Rocky Mountain, Space
                City Home Network, Bally Sports, Marquee Sports Network, Root Sports Northwest, MSG,
                MSG+, NBC Sports Bay Area, NBC Sports Boston, NBC Sports California, NBC Sports
                California+, NBC Sports Chicago, NBC Sports Chicago+, NBC Sports Philadelphia, NBC
                Sports Philadelphia+, NBC Sports Washington, NESN, YES Network and more to come.
              </p>
            </div>

            <div>
              <h3>What are some shows I can watch on Fubo?</h3>
              <p>
                Choose from thousands of on-demand titles each month, including hit shows from FOX,
                CBS, NBC, ABC, MTV, FX and more. This includes TV series like Survivor, The
                Simpsons, Abbott Elementary, Bob’s Burgers, The Bachelor, Chicago Fire, 9-1-1,
                Yellowstone, Saturday Night Live, The Five, The Rachel Maddow Show, The Conners,
                Family Guy, NCIS, The Masked Singer, Law & Order: SVU, Billions…the list keeps
                growing.
              </p>
            </div>

            <div>
              <h3>What devices can I watch Fubo on?</h3>
              <p>
                You can watch Fubo on your computer, iPhone, iPad, Android phone and tablet, Roku,
                Apple TV, Xbox, Chromecast and Amazon Fire TV.{' '}
                <a
                  href="https://support.fubo.tv/hc/en-us/articles/115002183228-What-devices-can-I-watch-fuboTV-on-"
                  target="_blank"
                  rel="noreferrer"
                >
                  Here's a full list of devices.
                </a>
              </p>
            </div>

            <div>
              <h3>Additional Questions</h3>
              <p>
                If you have any other questions please feel free to contact{' '}
                <a href="mailto:partners@fubo.tv">partners@fubo.tv</a>
              </p>
            </div>
          </div>
          <div className="bottom-cta-container">
            <a
              href="https://app.impact.com/campaign-promo-signup/fuboTV.brand?execution=e6s1"
              className="button-cta"
            >
              Apply Now
            </a>
          </div>
        </div>
      </StyledAffiliate>
    </Layout>
  )
}

export default AffiliateTemplate

const StyledAffiliate = styled.div`
  nav {
    font-weight: 700;
    background-color: #242424;
    .logo {
      display: flex;
      img {
        width: 125px;
        height: 42px;
        object-fit: contain;
      }
    }
    .nav-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 10px;
      max-width: 1600px;
      width: 90%;
      margin: 0 auto;
    }
  }
  .main-content {
    p {
      font-size: 16px;
    }
    padding: 70px 30px;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    h1 {
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      margin: 5px 0;
    }
    h2 {
      font-weight: 700;
      font-size: 30px;
      text-align: center;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 18px;
      text-align: center;
      color: #777;
    }
    hr {
      margin-top: 30px;
      margin-bottom: 30px;
      border: 0;
      border-top: 1px solid #eee;
    }
    .section {
      &.mb {
        margin-bottom: 60px;
      }
      > div {
        margin: 30px 0;
      }
      .device-img {
        text-align: center;
        img {
          width: 100%;
          max-width: 600px;
        }
      }
    }
    p {
      a {
        font-weight: 700;
        color: #5bc0de;
      }
    }
  }

  .button-cta {
    font-size: 18px;
    background: #fa4616;
    padding: 12px 18px;
    font-weight: 700;
    text-transform: uppercase;
    width: 320px;
    max-width: 85%;
    text-align: center;
  }
  .bottom-cta-container {
    text-align: center;
    .button-cta {
      display: block;
      font-size: 24px;
      padding: 30px 30px;
      margin: 50px auto 0;
    }
  }
`
