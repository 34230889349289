import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USDefaultTemplate
    location={props.location}
    lang="en"
    market="us"
    offerCode="pro-carolina-hurricanes-14d-trial"
    offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
    video={videos.bally_hurricanes.videoUrl}
    staticAnnotation={videos.bally_hurricanes.staticAnnotation}
    title="Watch Carolina Hurricanes hockey"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream Bally Sports from any device. No cable required."
    networkSet="hockey"
    customNetworks={['ballysportssouth']}
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    teamId={teamIds['Carolina Hurricanes']}
    // logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-carolina-hurricanes.png?ch=width&auto=format,compress"
    // logoHeight="100px"
  />
)
