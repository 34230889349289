import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

const BallySportsMLB = props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={props.video || videos.generic_baseball.videoUrl}
    staticAnnotation={props.staticAnnotation}
    title={props.title}
    subtitle={props.subtitle}
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="baseball"
    customNetworks={props.customNetworks}
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    {...props}
  />
)

export default BallySportsMLB
