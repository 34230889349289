import {
  CohortingEvent,
  CohortingEvent_EventName,
  CohortingEvent_Properties,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/cohorting_event.ts'
import { formatCohortingMessage } from '../utils.ts'

export { CohortingEvent_Properties as CohortingEventProperties } from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/cohorting_event.ts'

export const cohortingEvent = ({
  properties,
  category,
  subCategory,
}: {
  properties: CohortingEvent_Properties
  category: string
  subCategory: string
}) => {
  const message: CohortingEvent = {
    event: CohortingEvent_EventName.cohorting_event,
    message_id: '',
    properties: {
      ...properties,
      // @ts-expect-error - Cohorting type does not currently include these.
      attribute: {
        event_category: category,
        event_sub_category: subCategory,
      },
    },
    type: 'track',
  }

  const schema = formatCohortingMessage(CohortingEvent, message)

  return schema
}
