import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      video={videos.colorado_avalanche.videoUrl}
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/GettyImages-1484175988.jpg?ch=width&auto=format,compress"
      staticAnnotation={networkLogos.altitude}
      title="Watch Colorado hockey live"
      subtitle="STREAM THE AVALANCHE ON ALTITUDE SPORTS"
      tagline="Anytime. Anywhere. From any device."
      networkSet="hockey"
      customNetworks={['altitude']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-colorado-avalanche-v2.png?ch=width&auto=format,compress"
      logoHeight="100px"
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      teamId={teamIds['Colorado Avalanche']}
    />
  )
}
