import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'

export const cableData = {
  competitor: 'Cable',
  competitorColor: '#FFFFFF',
  heroData: {
    title: 'Looking for the best live TV service?',
    subtitle:
      'Compare Fubo & cable on price, features, channels, and add-ons to see which is better for you.',
  },
  generalCompareData: {
    heading: 'Compare Fubo vs. Cable TV',
    subheading:
      'Here’s a general look at both to help you determine which is the best live TV service for you.',
    listItems: [
      'Channel Count',
      'Hardware',
      'Cancellation Policy',
      'Local Broadcast Channels',
      'Starting Price',
    ],
    fuboList: [
      '185+',
      'None required',
      'Flexible, no fee',
      'Yes, at no extra charge',
      '$79.99/mo*',
    ],
    competitorList: [
      '156+',
      'Cable box required',
      'Fee varies with contract',
      'Yes, with broadcast TV fee',
      '$100.20/mo*',
    ],
    note:
      '*Prices may vary with taxes and fees. Price for average cable is based on the average cost of the base plans for 5 major cable companies, Cox Communications, Dish Network, Optimum, Spectrum and Xfinity, and includes the average broadcast TV fee and rental cost of a single set-top box.',
  },
  prosConsData: {
    heading: 'Pros and Cons: Fubo vs. Cable',
    content: (
      <>
        <p>
          Cable is the staple of the industry, so the pros and cons are pretty well-known already.
          Fubo is considered one of the top cable replacement options today. It can compete with
          cable's channel lineup and still offers the significant savings and unique flexibility of
          a streaming service.
        </p>
        <p>
          Fubo is a premium product for streaming and costs a lot less overall vs. cable TV. Fubo
          plans are loaded with live channels (at least 185 live channels) and quality features.
          It’s worth noting, Fubo has a lot of channels included in their most affordable plan, and
          many of those channels are among the most-watched channels of 2023. Fubo also includes the
          local broadcast for ABC, CBS, FOX and NBC in most markets without an additional broadcast
          TV charge, a hidden fee commonly found on a cable TV bill. One of the biggest benefits
          with Fubo over a cable TV provider is their flexibility. Since there are no contracts or
          long-term commitments, you can change your plan, add or drop upgrades, or cancel anytime
          without a financial penalty.
        </p>
        <p>
          The biggest gripe with cable TV has always been its pricing structure. Standalone pricing
          for live TV is usually difficult to decipher and oftentimes shrouded within complicated
          bundles of unwanted benefits or sales prices with contractual obligations. Most cable
          providers forcefully funnel new customers into a multi-year contract for a bundle service
          with phone lines, a fixed internet speed, a narrow channel lineup and other unavoidable
          features with fees. This doesn’t even get into setup costs and frustrations like cable box
          rentals and installation charges.
        </p>
      </>
    ),
  },
  plansData: {
    heading: 'Compare Fubo vs. Cable',
    subheading:
      'Let’s take a look at the differences between the popular Fubo Pro plan and a cable company’s average base plan.',
    tableData: {
      Plan: ['Pro plan', 'base plan'],
      'Live Channels': ['185+', '156+'],
      'Base Plan Price': ['$79.99', '$74.40*'],
      'Average broadcast TV fee': ['$0', '$18.37'],
      'Average equipment rental cost': ['$0', '$7.43/mo. per device'],
      'No installation fee': [true, false],
      'Free Trial': [true, false],
      'No cancellation fee': [true, false],
      'Regional sports networks': [true, true],
    },
    note:
      '*Average cost of the base plans for 5 major cable companies, Cox Communications, Dish Network, Optimum, Spectrum and Xfinity.',
  },
  channelsData: {
    heading: 'Compare Fubo Pro Channels vs. Basic Cable Plan',
    subheading:
      'There are a lot of overall similarities between Fubo channel lineup and your average cable channel lineup. This chart compares which networks are available in the Fubo Pro plan vs a cable company’s average base plan. Note, this is a very general outlook since availability varies with each cable company.',
    networksData: [
      { network: networkLogos.abc, fubo: true, competitor: true },
      { network: networkLogos.nbc, fubo: true, competitor: true },
      { network: networkLogos.cbs, fubo: true, competitor: true },
      { network: networkLogos.fox, fubo: true, competitor: true },
      { network: networkLogos.cbssports, fubo: true, competitor: false },
      { network: networkLogos.espn, fubo: true, competitor: true },
      { network: networkLogos.nflnetwork, fubo: true, competitor: false },
      { network: networkLogos.fs1, fubo: true, competitor: true },
      { network: networkLogos.bigten, fubo: true, competitor: false },
      { network: networkLogos.tudn, fubo: true, competitor: false },
      // { network: networkLogos.hgtv, fubo: true, competitor: true },
      // { network: networkLogos.magnolia, fubo: true, competitor: false },
      { network: networkLogos.disneyxd, fubo: true, competitor: false },
      { network: networkLogos.nickelodeon, fubo: true, competitor: true },
      { network: networkLogos.univision, fubo: true, competitor: false },
    ],
  },
  completedChannelsData: {
    heading: 'Complete Channel Comparison',
    subheading:
      'See how the live channel lineups compare between Fubo Pro plan and a cable company’s average base plan.',
    content: (
      <>
        <p>
          When it comes to comparing the overall channel lineup for Fubo and your average basic
          cable plan, it can be hard to tell the two apart. Fubo has a lot of the same channels you
          can get with cable at a very comparable price. The biggest difference has to be how the
          channels are distributed across plans, specifically for the Fubo Pro plan vs your average
          basic cable plan.
        </p>
        <p>
          When it comes to overall channels, the Fubo Pro plan definitely delivers a lot of popular
          TV channels, especially for sports. Fubo includes sports networks like NFL Network, CBS
          Sports Network, Big Ten Network, TUDN, and others right in their base plan. Popular sports
          channels like these usually find themselves in more expensive, higher-tier plans with
          cable services. This base plan access makes Fubo a top destination for users looking for
          better coverage of top sports, specifically for the NFL, college football, and major
          soccer leagues. With Disney XD, Nick Jr., Freeform, and others also included in their base
          plan, Fubo has a great channel lineup for families without needing a special plan or
          add-on.
        </p>
        <p>
          The average cable TV lineup obviously varies with the provider in your area. However,
          there are a few consistencies across most providers. For example, most providers have a
          Broadcast TV fee for access to the local ABC, CBS, FOX and NBC broadcast channels in your
          area. It’s very rare to find a basic cable plan that includes NFL Network, Big Ten Network
          or CBS Sports Network, forcing most customers into a sports add-on or more expensive plan.
          Most major cable providers do at least have the WarnerMedia networks. The average basic
          cable plan also rarely includes complementary channels for specific types of
          entertainment. For example, you can easily get Disney Channel with a basic plan. However,
          if you want Disney Junior too, you’ll need to pay for a premium upgrade.
        </p>
      </>
    ),
  },
  faqData: {
    qaData: [
      {
        id: 'fubo-faq-5',
        question: 'What channels does Fubo have vs. cable?',
        answer: (
          <p>
            Fubo and most cable providers have many of the same channels. However, Fubo has an edge
            with their Pro plan vs the average basic cable plan. The Fubo Pro plan includes major
            sports networks like NFL Network, Big Ten Network, CBS Sports Network as well as major
            entertainment networks like Disney XD and Univision.
          </p>
        ),
      },
    ],
    note: (
      <>
        *Up to date as of 6/8/2023 <br />
        Average prices and fees for the cable providers are cited from Cabletv.com.
      </>
    ),
  },
}
