import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectHeroPriceComparisonFlag } from 'src/modules/reducers/cohorts'

export default props => {
  let isHeroPriceActive = useSelector(selectHeroPriceComparisonFlag)
  console.log(isHeroPriceActive, 'isHeroPriceActive')
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle={!isHeroPriceActive ? 'ON ANY DEVICE. ANYTIME. ANYWHERE.' : 'ON ANY DEVICE.'}
      tagline="Watch and DVR the channels you love. No contracts, no commitment."
      showRSN={true}
      showMiniplayer="visible"
    />
  )
}
