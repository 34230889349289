export const offerCodes = {
  pro: 'pro-fubo-basic-v3',
  elite: 'us-recu-ga-elite_sportp-mo-7d-v1',
  premier: 'us-recu-ga-deluxe-mo-7d-v1',
  latino: 'us-recu-ga-latino-mo-7d-13amtoff1-v1',
  latino_qt: 'latino-bundle-quarterly-v6',
  // Canada 7-day free trial added 10/1/24
  canada_ent_mo: 'ca-recu-ga-entertainment-mo-7d-v1',
  canada_ent_qt: 'ca-recu-ga-entertainment-qt-7d-v1',
  canada_sports_mo: 'ca-recu-ga-sports-mo-7d-v1',
  canada_sports_qt: 'ca-recu-ga-sports-qt-7d-v1',
  canada_sports_yr: 'ca-recu-ga-sports-yr-7d-v1',
  canada_premium_mo: 'ca-recu-ga-premium-mo-7d-v1',
  canada_premium_qt: 'ca-recu-ga-premium-qt-7d-v1',
  canada_premium_yr: 'ca-recu-ga-premium-yr-7d-v1',
}
