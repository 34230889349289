import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

const BallySportsNHL = props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={props.video || videos.generic_hockey.videoUrl}
    staticAnnotation={props.staticAnnotation}
    title={props.title}
    subtitle={props.subtitle}
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="hockey"
    customNetworks={props.customNetworks}
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    {...props}
  />
)

export default BallySportsNHL
