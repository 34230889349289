import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/WhiteSox_1280x720_LP_15s.mp4"
    staticAnnotation={networkLogos.nbcschicago}
    title="Watch Chicago baseball live"
    subtitle="STREAM THE WHITE SOX ON NBC SPORTS CHICAGO"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="baseball"
    networkHeadline="The Best of Sports from the Channels You Love"
    customNetworks={['nbcschicago']}
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Chicago White Sox']}
  />
)
