import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    //nba league pass addon
    // addonCode="nbalp"
    ctaText="Start Watching"
    video={videos.nba.videoUrl}
    annotations={videos.nba.annotations}
    title="Watch NBA Games online with Fubo"
    subtitle="CATCH LIVE GAMES, SHOWS AND HIGHLIGHTS"
    tagline="Any time, any place, on all your devices"
    networkSet="basketball"
    league="nba"
    showRSN={true}
    // matchtickerSportId="59"
    // matchtickerLeagueName="NBA"
    // matchtickerLeagueId="191276"
    caRedirectUrl="/ca/nba-tv-ca/"
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
  />
)
