import { AppContext } from '@fubotv/fubo-schema-ts/common/v3/generic/app_context.ts'
import { Asset } from '@fubotv/fubo-schema-ts/common/v3/generic/asset.ts'
import { Cohorting } from '@fubotv/fubo-schema-ts/common/v3/generic/cohorting.ts'
import { DeviceType_DeviceTypeValue } from '@fubotv/fubo-schema-ts/common/v3/generic/common_enums.ts'
import { Core } from '@fubotv/fubo-schema-ts/common/v3/generic/core.ts'
import { Device, Device_InputMode } from '@fubotv/fubo-schema-ts/common/v3/generic/device.ts'
import { FuboContext } from '@fubotv/fubo-schema-ts/common/v3/generic/fubo_context.ts'
import { Growth } from '@fubotv/fubo-schema-ts/common/v3/generic/growth.ts'
import { Location } from '@fubotv/fubo-schema-ts/common/v3/generic/location.ts'
import { Navigation } from '@fubotv/fubo-schema-ts/common/v3/generic/navigation.ts'
import { Source } from '@fubotv/fubo-schema-ts/common/v3/generic/source.ts'
import { Ui } from '@fubotv/fubo-schema-ts/common/v3/generic/ui.ts'
import { UserProperties } from '@fubotv/fubo-schema-ts/common/v3/generic/user_properties.ts'
import { CohortingEvent_Properties } from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/cohorting_event.ts'
import { Partner } from '@fubotv/fubo-schema-ts/common/v3/generic/partner.ts'
import { Subscription } from '@fubotv/fubo-schema-ts/common/v3/generic/subscription.ts'
import { League } from '@fubotv/fubo-schema-ts/common/v3/generic/league.ts'
import { Sport } from '@fubotv/fubo-schema-ts/common/v3/generic/sport.ts'
import { Team } from '@fubotv/fubo-schema-ts/common/v3/generic/team.ts'

import {
  UiInteraction_Attribute_EventCategory_EventCategoryValue,
  UiInteraction_Attribute,
  UiInteraction_Attribute_EventSubCategory_EventSubCategoryValue,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/ui_interaction.ts'
import { Language } from '@fubotv/types/api/appconfig'
import { nanoid } from 'nanoid'

import { Location as locationData } from '@fubotv/types/api/location'

import { getEventProto } from './events/index.ts'
import pkg from '../../../../package.json'
import { getAppContext, getDeviceCategoryV3, getDeviceContext } from '../context/index.ts'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams/index.js'
import { parseMessage } from './utils.ts'
import {
  PageView_Attribute,
  PageView_Attribute_EventCategory,
  PageView_Attribute_EventCategory_EventCategoryValue,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/page_view.ts'

export type AnalyticsEvent = {
  name: string
  category: string
  subCategory: string
  eventContext?: {
    [key: string]: any
  }
  errorContext?: {
    [key: string]: unknown
  }
  subCategoryContext?: {
    [key: string]: unknown
  }
}

type Input = {
  anonymousId?: string
  event: AnalyticsEvent
  identity?: null | undefined
  language?: Language
  locationData?: locationData | null | undefined
  uniqueId?: string | null
}

// type Marketing = {
//   offer?: string | null
//   irad?: string | null
//   irmp?: string | null
//   sharedid?: string | null
//   'addons[]'?: Array<string> | null
//   ftv_campaign: string | null
// }

export function eventFormatter({
  anonymousId,
  event,
  identity,
  language,
  locationData,
  uniqueId,
}: Input): any {
  const userId = 'anonymous'
  const profileId = 'anonymous'
  const { name, category, subCategory, eventContext = {} } = event
  const { app_name, app_version, player_version } = getAppContext()
  const {
    browser_engine,
    browser_version: browser_engine_version,
    browser,
    device_type,
    os_version,
    os,
    runtime,
    user_agent,
  } = getDeviceContext()

  const deviceCategory = getDeviceCategoryV3()
  const isMobile = device_type !== '_'
  const deviceType = isMobile ? (device_type as keyof typeof DeviceType_DeviceTypeValue) : 'desktop'
  const inputMode = isMobile ? 'touch' : 'mouse'

  const queryParams = parseQueryParams(window.location.href)

  const appContext: AppContext = {
    app_name,
    app_version,
    player_version,
  }

  const asset: Asset = {}

  const cohorting: Cohorting[] = eventContext.cohorting || []

  const core: Core = {
    event_id: nanoid(),
    event_name: name,
    market: eventContext.market || '',
    profile_id: profileId,
    spec_version:
      pkg.dependencies['@fubotv/fubo-schema-ts']?.toString().replace('^', '') || '(unknown)',
    // @ts-expect-error
    event_time: new Date().toISOString(),
    x_forwarded_for: undefined, // string -  All ip addresses that the events going through (e.g "127.0.0.1, 127.0.0.2")
  }

  const device: Device = {
    browser_engine_version,
    browser_engine,
    browser,
    device_category: deviceCategory,
    device_id: uniqueId || '',
    device_type: DeviceType_DeviceTypeValue[deviceType],
    input_mode: Device_InputMode[inputMode],
    os_version,
    os,
    runtime,
    screen_height: window?.innerHeight.toString(),
    screen_width: window?.innerWidth.toString(),
    user_agent,
    device_manufacturer: '_', // (required) string - Device Manufacturer. (e.g. "Samsung")
    device_model: '_', // (required) string - Device Model. (e.g. "SM-G955U")
    advertising_id: undefined, // string - Advertising ID. (e.g. "0DFD0C7A-09E3-4E51-BC81-747FF63F2951")
    device_name: undefined, // string - Device Name. (e.g. "Roku Express")
    device_brand: undefined, // Device Brand. (e.g. "Samsung")
    appsflyer_device_id: undefined, // string - A device specific advertising ID used by appsflyer integration to perform attribution for fubo ad campaigns (e.g. "abcd-1234-efgh-5678")
    limit_ad_tracking: undefined, // boolean - If true, notifies appsflyer integration to ignore this device for attribution
  }

  const fuboContext: FuboContext = {
    fubo_service: `marketing`,
    fubo_namespace: 2,
    up_event: true,
  }

  const attributeUI: UiInteraction_Attribute = {
    event_category: UiInteraction_Attribute_EventCategory_EventCategoryValue['marketing'],
    event_sub_category: UiInteraction_Attribute_EventSubCategory_EventSubCategoryValue['marketing'],
  }

  const attributePageView: PageView_Attribute = {
    event_category: PageView_Attribute_EventCategory_EventCategoryValue['acquisition_context'],
  }

  const league: League = {
    league_id: eventContext.league_id,
    league_name: eventContext.league_name,
  }

  const sport: Sport = {
    sport_id: eventContext.sport_id,
    sport_name: eventContext.sport_name,
  }

  const team: Team = {
    team_id: eventContext.team_id,
  }

  const growth: Growth = {
    cta_name: eventContext.cta_name, // string: content_name 2
    cta_coordinates: undefined, // string
    cta_description: eventContext.cta_description, // string: measurement 3
    container_name: eventContext.container_name, //string: component 1
    container_coordinates: undefined, // string
    container_index: undefined, // number
    tab_name: eventContext.tab_name, // string
    tab_coordinates: undefined, // string
    tab_index: eventContext.tab_index, // number
  }

  const location: Location = {
    country_code: locationData?.countryCode,
    dma: locationData?.dma,
    language,
    postal_code: locationData?.postalCode,
    timezone: new Date().getTimezoneOffset().toString(),
    state: undefined, // string
    province: undefined, // string
  }

  const navigation: Navigation = {
    page: eventContext.page,
    filter: eventContext.lang, // string
    url: eventContext.url,
    app_section: eventContext.app_section, // string
    deeplink_url: undefined, // string
    page_variant: undefined,
    origin_page: eventContext.origin_page,
    origin_component: undefined, // string
    origin_element: eventContext.element,
    origin_component_index: eventContext.component_index,
    origin_component_type: undefined, // string
    origin_element_index: eventContext.element_index,
    origin_dialog_component: undefined, // string
    origin_dialog_element: undefined, // string
    origin_dialog_element_index: undefined, // number
    origin_app_section: undefined, // string
    origin_filter: eventContext.filter_term,
  }

  const source: Source = {
    referrer: undefined, // Source_Referrer enum
    utm_campaign: queryParams?.utm_campaign, // string
    utm_medium: queryParams?.utm_medium, // string
    utm_source: queryParams?.utm_source, // string
    utm_term: queryParams?.utm_term, // string
    ftv_campaign: queryParams?.ftv_campaign, // string
  }

  const subscription: Subscription = {
    product_add_ons: [], // string[]
    product_codes: [], // string[]
    rate_plan_code: queryParams?.offer, // string
  }

  const partner: Partner = {
    impact_radius_irad: queryParams?.irad,
    impact_radius_irmp: queryParams?.irmp,
  }

  const ui: Ui = {
    type: undefined, // string
    component: eventContext.component,
    above_fold: eventContext.above_fold,
    component_index: eventContext.component_index,
    element: eventContext.element,
    element_index: eventContext.element_index,
  }

  const userProperties: UserProperties = {
    anonymous_id: anonymousId,
    language,
    market: locationData?.countryCode,
    products: [], // string[]
    profile_id: profileId,
    subscription_add_ons_billing_status: {}, // Record<string, string>
    subscription_add_ons_state: {}, // Record<string, string>
    subscription_add_ons_trial: {}, // Record<string, string>
    subscription_base_billing_status: {}, // Record<string, string>
    subscription_base_state: {}, // Record<string, string>
    subscription_base_trial: {}, // Record<string, string>
    user_id: userId,
  }

  const data = {
    app_context: parseMessage<AppContext>(AppContext, appContext),
    asset: parseMessage<Asset>(Asset, asset),
    attribute:
      name == 'page_view'
        ? parseMessage<PageView_Attribute>(PageView_Attribute, attributePageView)
        : parseMessage<UiInteraction_Attribute>(UiInteraction_Attribute, attributeUI),
    cohorting: parseMessage<CohortingEvent_Properties>(
      CohortingEvent_Properties,
      { cohorting },
      'cohorting'
    ),
    core: parseMessage<Core>(Core, core),
    partner: parseMessage<Partner>(Partner, partner),
    device: parseMessage<Device>(Device, device),
    fubo_context: parseMessage<FuboContext>(FuboContext, fuboContext),
    growth: parseMessage<Growth>(Growth, growth),
    location: parseMessage<Location>(Location, location),
    navigation: parseMessage<Navigation>(Navigation, navigation),
    source: parseMessage<Source>(Source, source),
    subscription: parseMessage<Subscription>(Subscription, subscription),
    ui: parseMessage<Ui>(Ui, ui),
    user_properties: parseMessage<UserProperties>(UserProperties, userProperties),
    league: parseMessage<League>(League, league),
    sport: parseMessage<Sport>(Sport, sport),
    team: parseMessage<Team>(Team, team),
  }

  return getEventProto({ name, data, category, subCategory })
}
