import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      offerCode="pro-seattle-kraken-14d-trial"
      offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
      video={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/SeattleKraken2022_1280x720_LP_15s.mp4'
      }
      staticAnnotation={networkLogos.rootsports}
      title="Watch Seattle Kraken hockey"
      subtitle="LIVE GAMES, TEAM NEWS AND MORE"
      tagline="Stream it all on ROOT SPORTS from any device. Regional restrictions apply."
      networkSet="hockey"
      customNetworks={['rootsports']}
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      teamId={teamIds['Seattle Kraken']}
    />
  )
}
