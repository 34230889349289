import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    hasOfferInput={true}
    offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Trail_Blazers_2023_LP_14s.mp4'}
    title="Watch Portland Trail Blazers basketball"
    subtitle="LIVE GAMES, TEAM NEWS AND MORE"
    tagline="Stream it all on ROOT SPORTS from any device. Regional restrictions apply."
    networkSet={'basketball'}
    customNetworks={['rootsports']}
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    teamId={teamIds['Portland Trail Blazers']}
  />
)
