import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_heat.videoUrl}
    staticAnnotation={videos.bally_heat.staticAnnotation}
    title="Watch Miami basketball live"
    subtitle="STREAM THE HEAT ON BALLY SPORTS SUN"
    customNetworks={['ballysportssun']}
    teamId={teamIds['Miami Heat']}
  />
)
